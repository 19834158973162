var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"6d5ddcf3a936a4402019cee865013311fe930246"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from "@sentry/nextjs";
import { Replay } from "@sentry/browser";
import { excludeGraphQLFetch } from "apollo-link-sentry";

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

Sentry.init({
  enabled: !!process.env.ARAGRO_ENV,
  environment: process.env.ARAGRO_ENV,
  dsn:
    SENTRY_DSN ||
    "https://ad0a1b3a7b1942f0a2a475618e3076e3@o82583.ingest.sentry.io/1916813",
  // Adjust this value in production, or use tracesSampler for greater control
  ignoreErrors: [
    "ResizeObserver loop limit exceeded",
    "ResizeObserver loop completed with undelivered notifications.",
    "Non-Error promise rejection captured with value",
  ],
  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0.1,
  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 1.0,
  integrations: [new Replay({ maskAllInputs: false, maskAllText: false })],
  // By default, Sentry attaches all fetch events as breadcrumbs. Since `apollo-link-sentry` package tracks GraphQL requests as breadcrumbs,
  // they would show up duplicated in Sentry.
  beforeBreadcrumb: excludeGraphQLFetch,
  release: process.env.SENTRY_RELEASE,
});
